;(function($){
  $(document).ready(() => {
    $(".nav-link").each((i, item) => {
      let region = $(item).attr("data-region");

      $(item)
      .mouseover(function() {
        $(`.map a[data-region=${region}]`).addClass("active");
      })
      .mouseleave(function() {
        $(`.map a[data-region=${region}]`).removeClass("active");
      })
    })
  })
})(jQuery);